var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Input',{attrs:{"label":"الأسم بالعربي مفرد","type":"text","errorMessages":_vm.name_ar_single_Errors,"model":_vm.field.name_ar_single},on:{"changeValue":function (val) {
                  _vm.field.name_ar_single = val;
                  _vm.$v.field.name_ar_single.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Input',{attrs:{"label":"الأسم بالعربي جمع","type":"text","errorMessages":_vm.name_ar_plural_Errors,"model":_vm.field.name_ar_plural},on:{"changeValue":function (val) {
                  _vm.field.name_ar_plural = val;
                  _vm.$v.field.name_ar_plural.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Input',{attrs:{"label":"الأسم بالاجنبي مفرد","type":"text","errorMessages":_vm.name_en_single_Errors,"model":_vm.field.name_en_single},on:{"changeValue":function (val) {
                  _vm.field.name_en_single = val;
                  /*$v.form.name_en.$touch()*/
                }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Input',{attrs:{"label":"الأسم بالاجنبي جمع","type":"text","errorMessages":_vm.name_en_plural_Errors,"model":_vm.field.name_en_plural},on:{"changeValue":function (val) {
                  _vm.field.name_en_plural = val;
                  /*$v.form.name_en.$touch()*/
                }}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('div',[_c('Button',{attrs:{"color":"blue darken-1","type":"submit","label":"حفظ"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }