var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Input',{attrs:{"label":" اسم العقد بالعربي","type":"text","model":_vm.form.name_ar,"errorMessages":_vm.name_ar_Errors},on:{"changeValue":function (val) {_vm.form.name_ar = val
       _vm.$v.form.name_ar.$touch()}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Input',{attrs:{"label":" اسم العقد بالاجنبي","type":"text","model":_vm.form.name_en,"errorMessages":_vm.name_en_Errors},on:{"changeValue":function (val) {_vm.form.name_en = val
       _vm.$v.form.name_en.$touch()}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Input',{attrs:{"label":"عدد الترشيحات","type":"number","model":_vm.form.candidates_count,"errorMessages":_vm.candidates_countErrors},on:{"changeValue":function (val) {_vm.form.candidates_count = val
       _vm.$v.form.candidates_count.$touch()}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Input',{attrs:{"label":"عدد العروض","type":"number","model":_vm.form.offers_count,"errorMessages":_vm.offers_countErrors},on:{"changeValue":function (val) {_vm.form.offers_count = val
       _vm.$v.form.offers_count.$touch()}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Input',{attrs:{"label":" عدد ايام تميز العقار","type":"special_offers_days_count","model":_vm.form.special_offers_days_count,"errorMessages":_vm.special_offers_days_countErrors},on:{"changeValue":function (val) {_vm.form.special_offers_days_count = val
       _vm.$v.form.special_offers_days_count.$touch()}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Input',{attrs:{"label":"السعر","type":"number","model":_vm.form.price,"errorMessages":_vm.priceErrors},on:{"changeValue":function (val) {_vm.form.price = val
       _vm.$v.form.price.$touch()}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('Input',{attrs:{"label":"مدة العقد بالأيام","type":"number","model":_vm.form.expire_period,"errorMessages":_vm.expire_periodErrors},on:{"changeValue":function (val) {_vm.form.expire_period = val
       _vm.$v.form.expire_period.$touch()}}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('div',[_c('Button',{attrs:{"color":"blue darken-1","type":"submit","label":"حفظ"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }