var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isLoad)?_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Input',{attrs:{"label":"الحد الأدنى من صور العقارات","type":"number","model":_vm.form.minimum_count_of_estate_images_to_publish,"errorMessages":_vm.minimum_count_of_estate_images_to_publishErrors},on:{"changeValue":function (val) {
                    _vm.form.minimum_count_of_estate_images_to_publish = val;
                    _vm.$v.form.minimum_count_of_estate_images_to_publish.$touch();
                  }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Input',{attrs:{"label":"الحد الأعلى من صور العقارات","type":"number","model":_vm.form.maximum_count_of_estate_images,"errorMessages":_vm.maximum_count_of_estate_imagesErrors},on:{"changeValue":function (val) {
                    _vm.form.maximum_count_of_estate_images = val;
                    _vm.$v.form.maximum_count_of_estate_images.$touch();
                  }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Input',{attrs:{"label":"الحد الأعلى من صور الطوابق","type":"number","model":_vm.form.maximum_count_of_floor_plan_images,"errorMessages":_vm.maximum_count_of_floor_plan_imagesErrors},on:{"changeValue":function (val) {
                    _vm.form.maximum_count_of_floor_plan_images = val;
                    _vm.$v.form.maximum_count_of_floor_plan_images.$touch();
                  }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Input',{attrs:{"label":"الحد الأعلى من صور مخطط العقار","type":"number","model":_vm.form.maximum_count_of_floor_plan_images,"errorMessages":_vm.maximum_count_of_floor_plan_imagesErrors},on:{"changeValue":function (val) {
                    _vm.form.maximum_count_of_floor_plan_images = val;
                    _vm.$v.form.maximum_count_of_floor_plan_images.$touch();
                  }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Input',{attrs:{"label":"الحد الاعلى من  المناطق المجاورة","type":"number","model":_vm.form.maximum_count_of_nearby_places,"errorMessages":_vm.maximum_count_of_nearby_placesErrors},on:{"changeValue":function (val) {
                    _vm.form.maximum_count_of_nearby_places = val;
                    _vm.$v.form.maximum_count_of_nearby_places.$touch();
                  }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Input',{attrs:{"label":"الحد الاعلى من  صور الشارع","type":"number","model":_vm.form.maximum_count_of_street_images,"errorMessages":_vm.maximum_count_of_street_imagesErrors},on:{"changeValue":function (val) {
                    _vm.form.maximum_count_of_street_images = val;
                    _vm.$v.form.maximum_count_of_street_images.$touch();
                  }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Input',{attrs:{"label":"هاتف الشركة العادي","type":"number","model":_vm.form.normal_company_phone_number,"errorMessages":_vm.normal_company_phone_numberErrors},on:{"changeValue":function (val) {
                    _vm.form.normal_company_phone_number = val;
                    _vm.$v.form.normal_company_phone_number.$touch();
                  }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Input',{attrs:{"label":"هاتف الشركة الدائم","type":"number","model":_vm.form.permanent_company_phone_number,"errorMessages":_vm.permanent_company_phone_numberErrors},on:{"changeValue":function (val) {
                    _vm.form.permanent_company_phone_number = val;
                    _vm.$v.form.permanent_company_phone_number.$touch();
                  }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Input',{attrs:{"label":"عدد أيام أنتهاء صلاحية الاشعارات","type":"number","model":_vm.form.days_of_contract_expiration_to_notify,"errorMessages":_vm.days_of_contract_expiration_to_notifyErrors},on:{"changeValue":function (val) {
                    _vm.form.days_of_contract_expiration_to_notify = val;
                    _vm.$v.form.days_of_contract_expiration_to_notify.$touch();
                  }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Input',{attrs:{"label":"عدد أيام حذف طلب العقار","type":"number","model":_vm.form.days_of_estate_order_to_delete,"errorMessages":_vm.days_of_estate_order_to_deleteErrors},on:{"changeValue":function (val) {
                    _vm.form.days_of_estate_order_to_delete = val;
                    _vm.$v.form.days_of_estate_order_to_delete.$touch();
                  }}})],1),_c('Switcher',{attrs:{"label":"هل تريد تغير كلمة سر النظام ؟","model":_vm.checkbox},on:{"switcher":function($event){_vm.checkbox = !_vm.checkbox}}}),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Input',{attrs:{"disabled":!_vm.checkbox,"label":"كلمة سر النظام","type":"password","errorMessages":_vm.system_passwordErrors},on:{"changeValue":function (val) {
                    _vm.form.system_password = val;
                    _vm.isChangePassword = true;
                    _vm.$v.form.system_password.$touch();
                  }}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('div',[_c('Button',{attrs:{"color":"blue darken-1","type":"submit","label":"حفظ"}})],1)],1)],1)],1):_c('Loading')],1)}
var staticRenderFns = []

export { render, staticRenderFns }