var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{attrs:{"color":"grey"}},[_c('img',{staticStyle:{"height":"80px","min-width":"80px","width":"80px","border-radius":"50%"},attrs:{"width":"150","src":_vm.form.newLogo ? _vm.form.newLogo : "" + _vm.img_baseUrl + _vm.form.logo}})])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-file-input',{attrs:{"dense":"","accept":"images/*","prepend-icon":"mdi-camera","label":"شعار العقار"},on:{"change":_vm.selectImage}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('Input',{attrs:{"label":"الاسم","type":"text","model":_vm.form.name,"errorMessages":_vm.nameErrors},on:{"changeValue":function (val) {
                  _vm.form.name = val;
                  _vm.$v.form.name.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('Input',{attrs:{"label":"الهاتف","type":"text","model":_vm.form.telephone},on:{"changeValue":function (val) {
                  _vm.form.telephone = val;
                }}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('Input',{attrs:{"label":"longitude","type":"number","model":_vm.form.longitude,"errorMessages":_vm.longitudeErrors},on:{"changeValue":function (val) {
                  _vm.form.longitude = val;
                  _vm.$v.form.longitude.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('Input',{attrs:{"label":"latitude","type":"text","model":_vm.form.latitude,"errorMessages":_vm.latitudeErrors},on:{"changeValue":function (val) {
                  _vm.form.latitude = val;
                  _vm.$v.form.latitude.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('FormSelect',{attrs:{"items":_vm.Locations,"model":_vm.form.location_id,"attr":"location_id","errorMessages":_vm.location_idErrors,"label":"ضمن المنطقة"},on:{"select":function (val) {
                  _vm.form.location_id = val.value;
                  _vm.$v.form.location_id.$touch();
                }}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('div',[_c('Button',{attrs:{"color":"blue darken-1","type":"submit","label":"حفظ"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }